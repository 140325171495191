import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Grid as MuiGrid } from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import cx from "classnames";

const Grid = styled(MuiGrid)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(3)}px`,
    marginBottom: 100,

    '@media print': {
      marginBottom: 0,
    },
  },
  inner: {
    paddingTop: 0,
    maxWidth: theme.constants.maxWidth,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const Section = forwardRef(
  ({ id, children, sectionClassName, innerClassName, ...rest }, ref) => {
    const classes = useStyles();
    return (
      <section ref={ref} className={cx(classes.root, sectionClassName)} id={id}>
        <Grid className={cx(classes.inner, innerClassName)} {...rest}>
          {children}
        </Grid>
      </section>
    );
  },
);

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sectionClassName: PropTypes.string,
  innerClassName: PropTypes.string,
};

Section.displayName = "Section";

export default Section;
