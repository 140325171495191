import Clipboard from "../assets/images_png/hero/Clipboard.png";
import Clipboard2xImg from "../assets/images_png/hero/Clipboard@2x.png";
import Manufacturing from "../assets/images_png/hero/Manufacturing.png";
import Manufacturing2xImg from "../assets/images_png/hero/Manufacturing@2x.png";
import SCBA from "../assets/images_png/hero/SCBA.png";
import SCBA2xImg from "../assets/images_png/hero/SCBA@2x.png";
import Backhoe from "../assets/images_png/hero/Backhoe.png";
import Backhoe2xImg from "../assets/images_png/hero/Backhoe@2x.png";

import Clipboard_webp from "../assets/images/hero/Clipboard.webp";
import Clipboard2xImg_webp from "../assets/images/hero/Clipboard@2x.webp";
import Manufacturing_webp from "../assets/images/hero/Manufacturing.webp";
import Manufacturing2xImg_webp from "../assets/images/hero/Manufacturing@2x.webp";
import SCBA_webp from "../assets/images/hero/SCBA.webp";
import SCBA2xImg_webp from "../assets/images/hero/SCBA@2x.webp";
import Backhoe_webp from "../assets/images/hero/Backhoe.webp";
import Backhoe2xImg_webp from "../assets/images/hero/Backhoe@2x.webp";

import iPhoneImg from "../assets/images_png/hero/iPhone_Angled.png";
import iPhoneImg_webp from "../assets/images/hero/iPhone_Angled.webp";

import ClipboardQrImg from "../assets/images_png/hero/Clipboard_qr.png";
import ManufacturingQrImg from "../assets/images_png/hero/Manufacturing_qr.png";
import SCBAQrImg from "../assets/images_png/hero/SCBA_qr.png";
import BackhoeQrImg from "../assets/images_png/hero/Backhoe_qr.png";
import ClipboardQrImg_webp from "../assets/images/hero/Clipboard_qr.webp";
import ManufacturingQrImg_webp from "../assets/images/hero/Manufacturing_qr.webp";
import SCBAQrImg_webp from "../assets/images/hero/SCBA_qr.webp";
import BackhoeQrImg_webp from "../assets/images/hero/Backhoe_qr.webp";

import ClipboardSlateImg from "../assets/images_png/hero/hero_healthcheck.png";
import ClipboardSlate2xImg from "../assets/images_png/hero/hero_healthcheck@2x.png";
import ManufacturingSlateImg from "../assets/images_png/hero/hero_manufacturing.png";
import ManufacturingSlate2xImg from "../assets/images_png/hero/hero_manufacturing@2x.png";
import SCBASlateImg from "../assets/images_png/hero/hero_scba.png";
import SCBASlate2xImg from "../assets/images_png/hero/hero_scba@2x.png";
import BackhoeSlateImg from "../assets/images_png/hero/hero_backhoe.png";
import BackhoeSlate2xImg from "../assets/images_png/hero/hero_backhoe@2x.png";
import ClipboardSlateImg_webp from "../assets/images/hero/hero_healthcheck.webp";
import ClipboardSlate2xImg_webp from "../assets/images/hero/hero_healthcheck@2x.webp";
import ManufacturingSlateImg_webp from "../assets/images/hero/hero_manufacturing.webp";
import ManufacturingSlate2xImg_webp from "../assets/images/hero/hero_manufacturing@2x.webp";
import SCBASlateImg_webp from "../assets/images/hero/hero_scba.webp";
import SCBASlate2xImg_webp from "../assets/images/hero/hero_scba@2x.webp";
import BackhoeSlateImg_webp from "../assets/images/hero/hero_backhoe.webp";
import BackhoeSlate2xImg_webp from "../assets/images/hero/hero_backhoe@2x.webp";

import SlateView from "../assets/images_png/hero/SlateView.png";
import SlateView2xImg from "../assets/images_png/hero/SlateView@2x.png";
import SlateView_webp from "../assets/images/hero/SlateView.webp";
import SlateView2xImg_webp from "../assets/images/hero/SlateView@2x.webp";


// benefits
import Logo from "../assets/images_png/benefits/animation/Logo-White.png";
import Logo2xImg from "../assets/images_png/benefits/animation/Logo-White@2x.png";
import Logo_webp from "../assets/images/benefits/animation/Logo-White.webp";
import Logo2xImg_webp from "../assets/images/benefits/animation/Logo-White@2x.webp";
import Avatar from "../assets/images_png/benefits/animation/Profile.png";
import Avatar2xImg from "../assets/images_png/benefits/animation/Profile@2x.png";
import Avatar_webp from "../assets/images/benefits/animation/Profile.webp";
import Avatar2xImg_webp from "../assets/images/benefits/animation/Profile@2x.webp";
import Fav1 from "../assets/images_png/benefits/animation/Fav1.png";
import Fav12xImg from "../assets/images_png/benefits/animation/Fav1@2x.png";
import Fav1_webp from "../assets/images/benefits/animation/Fav1.webp";
import Fav12xImg_webp from "../assets/images/benefits/animation/Fav1@2x.webp";
import Fav2 from "../assets/images_png/benefits/animation/Fav2.png";
import Fav22xImg from "../assets/images_png/benefits/animation/Fav2@2x.png";
import Fav2_webp from "../assets/images/benefits/animation/Fav2.webp";
import Fav22xImg_webp from "../assets/images/benefits/animation/Fav2@2x.webp";
import Stack1 from "../assets/images_png/benefits/animation/Construction.png";
import Stack12xImg from "../assets/images_png/benefits/animation/Construction@2x.png";
import Stack1_webp from "../assets/images/benefits/animation/Construction.webp";
import Stack12xImg_webp from "../assets/images/benefits/animation/Construction@2x.webp";
import Stack2 from "../assets/images_png/benefits/animation/Fire.png";
import Stack22xImg from "../assets/images_png/benefits/animation/Fire@2x.png";
import Stack2_webp from "../assets/images/benefits/animation/Fire.webp";
import Stack22xImg_webp from "../assets/images/benefits/animation/Fire@2x.webp";
import Stack3 from "../assets/images_png/benefits/animation/Manufacturing.png";
import Stack32xImg from "../assets/images_png/benefits/animation/Manufacturing@2x.png";
import Stack3_webp from "../assets/images/benefits/animation/Manufacturing.webp";
import Stack32xImg_webp from "../assets/images/benefits/animation/Manufacturing@2x.webp";
import AllStacks from "../assets/images_png/benefits/animation/AllStack.png";
import AllStacks2xImg from "../assets/images_png/benefits/animation/AllStack@2x.png";
import AllStacks_webp from "../assets/images/benefits/animation/AllStack.webp";
import AllStacks2xImg_webp from "../assets/images/benefits/animation/AllStack@2x.webp";
import MainBar from "../assets/images_png/benefits/animation/MainBar.png";
import MainBar2xImg from "../assets/images_png/benefits/animation/MainBar@2x.png";
import MainBar_webp from "../assets/images/benefits/animation/MainBar.webp";
import MainBar2xImg_webp from "../assets/images/benefits/animation/MainBar@2x.webp";
import Slate1 from "../assets/images_png/benefits/animation/Slate1.png";
import Slate12xImg from "../assets/images_png/benefits/animation/Slate1@2x.png";
import Slate1_webp from "../assets/images/benefits/animation/Slate1.webp";
import Slate12xImg_webp from "../assets/images/benefits/animation/Slate1@2x.webp";
import Slate2 from "../assets/images_png/benefits/animation/Slate2.png";
import Slate22xImg from "../assets/images_png/benefits/animation/Slate2@2x.png";
import Slate2_webp from "../assets/images/benefits/animation/Slate2.webp";
import Slate22xImg_webp from "../assets/images/benefits/animation/Slate2@2x.webp";
import Slate3 from "../assets/images_png/benefits/animation/Slate3.png";
import Slate32xImg from "../assets/images_png/benefits/animation/Slate3@2x.png";
import Slate3_webp from "../assets/images/benefits/animation/Slate3.webp";
import Slate32xImg_webp from "../assets/images/benefits/animation/Slate3@2x.webp";
import Slate4 from "../assets/images_png/benefits/animation/Slate4.png";
import Slate42xImg from "../assets/images_png/benefits/animation/Slate4@2x.png";
import Slate4_webp from "../assets/images/benefits/animation/Slate4.webp";
import Slate42xImg_webp from "../assets/images/benefits/animation/Slate4@2x.webp";
import FAB from "../assets/images_png/benefits/animation/FAB.png";
import FAB2xImg from "../assets/images_png/benefits/animation/FAB@2x.png";
import FAB_webp from "../assets/images/benefits/animation/FAB.webp";
import FAB2xImg_webp from "../assets/images/benefits/animation/FAB@2x.webp";
import IndustryImg1 from "../assets/images_png/industry/industry1_2x.png";
import IndustryImg2 from "../assets/images_png/industry/industry2_2x.png";
import IndustryImg3 from "../assets/images_png/industry/industry3_2x.png";
import IndustryImg4 from "../assets/images_png/industry/industry4_2x.png";
import IndustryImg1_webp from "../assets/images/industry/industry1_2x.webp";
import IndustryImg2_webp from "../assets/images/industry/industry2_2x.webp";
import IndustryImg3_webp from "../assets/images/industry/industry3_2x.webp";
import IndustryImg4_webp from "../assets/images/industry/industry4_2x.webp";

// feature
import Map from "../assets/images_png/feature/8_Map.png";
import Map2x from "../assets/images_png/feature/8_Map@2x.png";
import Map_webp from "../assets/images/feature/8_Map.webp";
import Map2x_webp from "../assets/images/feature/8_Map@2x.webp";
import Dashboard from "../assets/images_png/feature/7_Dashboard.png";
import Dashboard2x from "../assets/images_png/feature/7_Dashboard@2x.png";
import Dashboard_webp from "../assets/images/feature/7_Dashboard.webp";
import Dashboard2x_webp from "../assets/images/feature/7_Dashboard@2x.webp";
import Privacy2x from "../assets/images_png/feature/6_Privacy@2x.png";
import Privacy from "../assets/images_png/feature/6_Privacy.png";
import Privacy_webp from "../assets/images/feature/6_Privacy.webp";
import Privacy2x_webp from "../assets/images/feature/6_Privacy@2x.webp";
import Search from "../assets/images_png/feature/5_Search.png";
import Search2x from "../assets/images_png/feature/5_Search@2x.png";
import Search_webp from "../assets/images/feature/5_Search.webp";
import Search2x_webp from "../assets/images/feature/5_Search@2x.webp";
import ChangeTracking from "../assets/images_png/feature/4_ChangeTracking.png";
import ChangeTracking2x from "../assets/images_png/feature/4_ChangeTracking@2x.png";
import ChangeTracking_webp from "../assets/images/feature/4_ChangeTracking.webp";
import ChangeTracking2x_webp from "../assets/images/feature/4_ChangeTracking@2x.webp";
import AssetTags from "../assets/images_png/feature/3_AssetTags.png";
import AssetTags2x from "../assets/images_png/feature/3_AssetTags@2x.png";
import AssetTags_webp from "../assets/images/feature/3_AssetTags.webp";
import AssetTags2x_webp from "../assets/images/feature/3_AssetTags@2x.webp";
import MobileTablet from "../assets/images_png/feature/2_MobileTablet.png";
import MobileTablet2x from "../assets/images_png/feature/2_MobileTablet@2x.png";
import MobileTablet_webp from "../assets/images/feature/2_MobileTablet.webp";
import MobileTablet2x_webp from "../assets/images/feature/2_MobileTablet@2x.webp";
import CustomizableFields from "../assets/images_png/feature/1_CustomizableFields.png";
import CustomizableFields2x from "../assets/images_png/feature/1_CustomizableFields@2x.png";
import CustomizableFields_webp from "../assets/images/feature/1_CustomizableFields.webp";
import CustomizableFields2x_webp from "../assets/images/feature/1_CustomizableFields@2x.webp";

//pricing
import Pricing2x from "../assets/images_png/pricing/Hero@2x.png";
import Pricing from "../assets/images_png/pricing/Hero.png";
import Pricing2x_webp from "../assets/images/pricing/Pricing@2x.webp";
import Pricing_webp from "../assets/images/pricing/Pricing.webp";
import Check from "../assets/images_png/pricing/Check Small.png";
import Cancel from "../assets/images_png/pricing/X.png";
import Check_webp from "../assets/images/pricing/Check Small.webp";
import Cancel_webp from "../assets/images/pricing/X.webp";


export const IMAGES = {
  home: {
    hero: {
      main: {
        png: {
          clipboard: {
            src: Clipboard,
            srcSet: Clipboard2xImg,
          },
          manufacturing: {
            src: Manufacturing,
            srcSet: Manufacturing2xImg,
          },
          SCBA: {
            src: SCBA,
            srcSet: SCBA2xImg,
          },
          backhoe: {
            src: Backhoe,
            srcSet: Backhoe2xImg,
          },
        },
        webp: {
          clipboard: {
            src: Clipboard_webp,
            srcSet: Clipboard2xImg_webp,
          },
          manufacturing: {
            src: Manufacturing_webp,
            srcSet: Manufacturing2xImg_webp,
          },
          SCBA: {
            src: SCBA_webp,
            srcSet: SCBA2xImg_webp,
          },
          backhoe: {
            src: Backhoe_webp,
            srcSet: Backhoe2xImg_webp,
          },
        }
      },
      phone: {
        png: {
          src: iPhoneImg,
          srcSet: iPhoneImg,
        },
        webp: {
          src: iPhoneImg_webp,
          srcSet: iPhoneImg_webp,
        }
      },
      qrImage: {
        png: {
          clipboard: {
            src: ClipboardQrImg,
            srcSet: ClipboardQrImg,
          },
          manufacturing: {
            src: ManufacturingQrImg,
            srcSet: ManufacturingQrImg,
          },
          SCBA: {
            src: SCBAQrImg,
            srcSet: SCBAQrImg,
          },
          backhoe: {
            src: BackhoeQrImg,
            srcSet: BackhoeQrImg,
          },
        },
        webp: {
          clipboard: {
            src: ClipboardQrImg_webp,
            srcSet: ClipboardQrImg_webp,
          },
          manufacturing: {
            src: ManufacturingQrImg_webp,
            srcSet: ManufacturingQrImg_webp,
          },
          SCBA: {
            src: SCBAQrImg_webp,
            srcSet: SCBAQrImg_webp,
          },
          backhoe: {
            src: BackhoeQrImg_webp,
            srcSet: BackhoeQrImg_webp,
          },
        }
      },
      slateView:{
        png: {
          src: SlateView,
          srcSet: SlateView2xImg,
        },
        webp: {
          src: SlateView_webp,
          srcSet: SlateView2xImg_webp,
        }
      },
      slate: {
        png: {
          clipboard: {
            src: ClipboardSlateImg,
            srcSet: ClipboardSlate2xImg,
          },
          manufacturing: {
            src: ManufacturingSlateImg,
            srcSet: ManufacturingSlate2xImg,
          },
          SCBA: {
            src: SCBASlateImg,
            srcSet: SCBASlate2xImg,
          },
          backhoe: {
            src: BackhoeSlateImg,
            srcSet: BackhoeSlate2xImg,
          },
        },
        webp: {
          clipboard: {
            src: ClipboardSlateImg_webp,
            srcSet: ClipboardSlate2xImg_webp,
          },
          manufacturing: {
            src: ManufacturingSlateImg_webp,
            srcSet: ManufacturingSlate2xImg_webp,
          },
          SCBA: {
            src: SCBASlateImg_webp,
            srcSet: SCBASlate2xImg_webp,
          },
          backhoe: {
            src: BackhoeSlateImg_webp,
            srcSet: BackhoeSlate2xImg_webp,
          },
        }
      },
    },
    benefits: {
      animation: {
        png: {
          logo: {
            src: Logo,
            srcSet: Logo2xImg,
          },
          avatar: {
            src: Avatar,
            srcSet: Avatar2xImg,
          },
          fav1: {
            src: Fav1,
            srcSet: Fav12xImg,
          },
          fav2: {
            src: Fav2,
            srcSet: Fav22xImg,
          },
          stack1: {
            src: Stack1,
            srcSet: Stack12xImg,
          },
          stack2: {
            src: Stack2,
            srcSet: Stack22xImg,
          },
          stack3: {
            src: Stack3,
            srcSet: Stack32xImg,
          },
          allStacks: {
            src: AllStacks,
            srcSet: AllStacks2xImg,
          },
          mainBar: {
            src: MainBar,
            srcSet: MainBar2xImg,
          },
          slate1: {
            src: Slate1,
            srcSet: Slate12xImg,
          },
          slate2: {
            src: Slate2,
            srcSet: Slate22xImg,
          },
          slate3: {
            src: Slate3,
            srcSet: Slate32xImg,
          },
          slate4: {
            src: Slate4,
            srcSet: Slate42xImg,
          },
          FAB: {
            src: FAB,
            srcSet: FAB2xImg,
          },
        },
        webp: {
          logo: {
            src: Logo_webp,
            srcSet: Logo2xImg_webp,
          },
          avatar: {
            src: Avatar_webp,
            srcSet: Avatar2xImg_webp,
          },
          fav1: {
            src: Fav1_webp,
            srcSet: Fav12xImg_webp,
          },
          fav2: {
            src: Fav2_webp,
            srcSet: Fav22xImg_webp,
          },
          stack1: {
            src: Stack1_webp,
            srcSet: Stack12xImg_webp,
          },
          stack2: {
            src: Stack2_webp,
            srcSet: Stack22xImg_webp,
          },
          stack3: {
            src: Stack3_webp,
            srcSet: Stack32xImg_webp,
          },
          allStacks: {
            src: AllStacks_webp,
            srcSet: AllStacks2xImg_webp,
          },
          mainBar: {
            src: MainBar_webp,
            srcSet: MainBar2xImg_webp,
          },
          slate1: {
            src: Slate1_webp,
            srcSet: Slate12xImg_webp,
          },
          slate2: {
            src: Slate2_webp,
            srcSet: Slate22xImg_webp,
          },
          slate3: {
            src: Slate3_webp,
            srcSet: Slate32xImg_webp,
          },
          slate4: {
            src: Slate4_webp,
            srcSet: Slate42xImg_webp,
          },
          FAB: {
            src: FAB_webp,
            srcSet: FAB2xImg_webp,
          },
        }
      }
    },
    industries: {
      png: {
        image_1:{
          src: IndustryImg1,
          srcSet: IndustryImg1,
        },
        image_2:{
          src: IndustryImg2,
          srcSet: IndustryImg2,
        },
        image_3:{
          src: IndustryImg3,
          srcSet: IndustryImg3,
        },
        image_4:{
          src: IndustryImg4,
          srcSet: IndustryImg4,
        },
      },
      webp: {
        image_1:{
          src: IndustryImg1_webp,
          srcSet: IndustryImg1_webp,
        },
        image_2:{
          src: IndustryImg2_webp,
          srcSet: IndustryImg2_webp,
        },
        image_3:{
          src: IndustryImg3_webp,
          srcSet: IndustryImg3_webp,
        },
        image_4:{
          src: IndustryImg4_webp,
          srcSet: IndustryImg4_webp,
        },
      }
    },
    features: {
      png: {
        map: {
          src: Map,
          srcSet: Map2x,
        },
        dashboard: {
          src: Dashboard,
          srcSet: Dashboard2x,
        },
        privacy: {
          src: Privacy,
          srcSet: Privacy2x,
        },
        search: {
          src: Search,
          srcSet: Search2x,
        },
        changeTracking: {
          src: ChangeTracking,
          srcSet: ChangeTracking2x,
        },
        assetTags: {
          src: AssetTags,
          srcSet: AssetTags2x,
        },
        mobileTablet: {
          src: MobileTablet,
          srcSet: MobileTablet2x,
        },
        customizableFields: {
          src: CustomizableFields,
          srcSet: CustomizableFields2x,
        },
      },
      webp: {
        map: {
          src: Map_webp,
          srcSet: Map2x_webp,
        },
        dashboard: {
          src: Dashboard_webp,
          srcSet: Dashboard2x_webp,
        },
        privacy: {
          src: Privacy_webp,
          srcSet: Privacy2x_webp,
        },
        search: {
          src: Search_webp,
          srcSet: Search2x_webp,
        },
        changeTracking: {
          src: ChangeTracking_webp,
          srcSet: ChangeTracking2x_webp,
        },
        assetTags: {
          src: AssetTags_webp,
          srcSet: AssetTags2x_webp,
        },
        mobileTablet: {
          src: MobileTablet_webp,
          srcSet: MobileTablet2x_webp,
        },
        customizableFields: {
          src: CustomizableFields_webp,
          srcSet: CustomizableFields2x_webp,
        },
      }
    },
    pricing: {
      png: {
        src: Pricing,
        srcSet: Pricing2x,
      },
      webp: {
        src: Pricing_webp,
        srcSet: Pricing2x_webp,
      }
    },
  },
  pricing: {
    png: {
      icon_check: {
        src: Check,
        srcSet: Check,
      },
      icon_x: {
        src: Cancel,
        srcSet: Cancel,
      },
    },
    webp: {
      icon_check: {
        src: Check_webp,
        srcSet: Check_webp,
      },
      icon_x: {
        src: Cancel_webp,
        srcSet: Cancel_webp,
      },
    },
  }
}